import qs from 'query-string';
import {
  BASE_URL_ELEMENT,
  PARTNER_ID_BPJS,
  PARTNER_ID_BPJS_KASBON,
  PARTNER_ID_GENERAL,
  PARTNER_ID_GENERAL_KASBON,
  PARTNER_ID_WEBVIEW_APK
} from '../common/config';
import moment from 'moment';
import {
  FIRST_APPLY_START,
  regexMotherName,
  SCHEME_CODE_KASBON,
  SCHEME_CODE_PNANG,
  SCHEME_CODE_PNNEW
} from 'common/constants';

export const updateObject = (oldObject, updatedProps) => {
  return {
    ...oldObject,
    ...updatedProps
  };
};

export const findObjectValue = (name, arr) => {
  if (!arr || !Array.isArray(arr) || arr.length === 0) {
    return {};
  }

  const value = arr.find(object => object.value === name);

  if (!value) return {};

  return value;
};

export const isObjectEmpty = value => {
  return value && Object.keys(value).length === 0;
};

export const checkMaxValue = (value, inputLength) => {
  if (value >= inputLength) {
    value = value.substring(0, inputLength);
  }
  return value;
};

export const toDate = dateString => {
  const dt = dateString.split(/-|\s/);
  return new Date(dt.slice(0, 3).reverse().join('-') + ' ' + dt[3]);
};

export const getTommorowDate = date => {
  const day = 60 * 60 * 24 * 1000;
  const tommorow = new Date(date.getTime() + day);

  return tommorow;
};

export const getNumberOfDays = (start, end) => {
  const startDate = start;
  const endDate = new Date(end);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = endDate.getTime() - startDate.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
};

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'Mei',
  'Jun',
  'Jul',
  'Agt',
  'Sep',
  'Okt',
  'Nov',
  'Des'
];

export const dueDateFormat = date => {
  let d = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
  d = new Date(d);
  const dueDate =
    d.getDate() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear();
  return dueDate;
};

export const dateSimplyMonthFormat = date => {
  const d = new Date(date);
  const simplyMonthFormat =
    d.getDate() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear();
  return simplyMonthFormat;
};

export const generateElementURL = (
  clientId,
  userId,
  txnId,
  hashToken,
  timeStamp
) => {
  const params = {
    CLIENTID: clientId,
    userId: userId,
    txnId: txnId,
    HASHTOKEN: hashToken,
    TIMESTAMP: timeStamp
  };
  const url = BASE_URL_ELEMENT + '?' + qs.stringify(params);

  return url;
};

export const thousandFormat = (num, separator) => {
  return num
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, separator ? separator : '.');
};

export const maskingNumber = number => {
  const masking = number.replace(/.(?=.{4})/g, '*');
  return masking;
};

export const maskingName = fullName => {
  let firstName = fullName?.split(' ').slice(0, -1).join(' ');
  let lastName = fullName?.split(' ').slice(-1).join(' ');

  if (!fullName) {
    return fullName;
  }

  const maskedStrFirstName = firstName
    ? firstName?.substring(0, 2) +
      '*'.repeat(5) +
      firstName?.substring(firstName?.length - 1)
    : '';
  const maskedStrLastName = lastName
    ? lastName?.substring(0, 2) +
      '*'.repeat(5) +
      lastName?.substring(lastName?.length - 1)
    : '';

  return maskedStrFirstName + ' ' + maskedStrLastName;
};

export const rupiahFormat = new Intl.NumberFormat('id-ID', {
  style: 'decimal',
  currency: 'IDR'
});

export const removeZero = val => {
  let str = val.toString();
  return str.slice(0, 2);
};

export const convertTime = time => {
  const minutes = Math.floor(time / 60);

  const seconds = time - minutes * 60;

  return `${padTime(minutes)}:${padTime(seconds)}`;
};

export const padTime = time => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

export const getMinutes = time => {
  const minutes = Math.floor((time % 3600) / 60);

  return `${padTime(minutes)}`;
};

export const getSecond = time => {
  const seconds = Math.floor((time % 3600) % 60);

  return `${padTime(seconds)}`;
};

export const getSecondsDifferent = value => {
  const present = new Date();
  const future = new Date(value);

  return Math.floor((future.getTime() - present.getTime()) / 1000);
};

export const setTimerFreeze = ({ key, freezeTime, failedCount }) => {
  const value = new Date();
  value.setMinutes(value.getMinutes() + freezeTime);
  localStorage.setItem(
    key,
    JSON.stringify({ freezeTime: value, failedCount: failedCount })
  );
};

export const creditCardFormating = ccNumber => {
  if (ccNumber === undefined) {
    return '';
  }
  if (typeof val === 'number') {
    ccNumber.toString();
  }
  return ccNumber.replace(
    /(\d{4})(\d{2})(\d{5})(\d{3})(\d{1,})/g,
    '$1-$2-$3-$4-$5'
  );
};

/**
 * Extract message code from och that placed under header > channelContext
 * @param err {Object} http response error
 * @returns {String} of error message code
 */
export const getChannelContextCode = (err, ctx = 'channelcontext') => {
  const channelContext = getChannelContext(err, ctx);

  if (!channelContext.status || !channelContext.status.message) return null;
  if (channelContext.status.message.length < 1) return null;

  const message = channelContext.status.message[0];

  if (!message.messageCode) return null;

  return message.messageCode;
};

/**
 *
 * @param err
 * @param {String} ctx
 */
export const getChannelContextMsg = (err, ctx = 'channel-context') => {
  const channelContext = getChannelContext(err, ctx);

  if (!channelContext.status || !channelContext.status.message) return null;
  if (channelContext.status.message.length < 1) return null;

  const message = channelContext.status.message[0];

  if (!message.messageDesc) return null;

  return message.messageDesc;
};

/**
 * @param err {Object} http response error
 */
export const getFirstAuthorizationMode = (err, ctx = 'channelcontext') => {
  const channelContext = getChannelContext(err, ctx);

  if (!channelContext.authorization) return null;
  if (!channelContext.authorization.firstAuthorizationMode) return null;

  return channelContext.authorization.firstAuthorizationMode;
};

const getChannelContext = (err, ctx) => {
  if (!err || !err.response || !err.response.headers) return {};
  if (!err.response.headers[ctx]) return {};

  const channelContext = JSON.parse(err.response.headers[ctx]);

  if (!channelContext) return {};

  return channelContext;
};

export const removeZeroDigit = (val, long) => {
  let letter = val.toString();
  if (long === 6) {
    letter = letter.replace(/[0]{6}$/g, '');
  } else {
    letter = val;
  }
  return letter;
};

export const getCashbackFromActiveLoan = (
  disbursementDate,
  sactionAmount,
  cashbackData
) => {
  if (!cashbackData || cashbackData.length == 0) return 0;

  let deltaDate;
  const activeDate = new Date();
  const disburseDate = new Date(disbursementDate);

  deltaDate = activeDate.getDate() - disburseDate.getDate();

  if (deltaDate === 0) deltaDate = 1;
  if (deltaDate === 2) deltaDate = 3;

  const getCashbackDetail = cashbackData.find(
    data => data.amount === Number(sactionAmount)
  );
  if (!getCashbackDetail) return 0;

  const cashBackAmount = getCashbackDetail.listCashback[deltaDate];
  if (!cashBackAmount || !cashBackAmount === undefined) return 0;

  return cashBackAmount;
};

export const upperCaseFront = wording => {
  return wording.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  });
};

export const camelize = str => {
  return upperCaseFront(str).replace(/\s+/g, '');
};

export const convertArray = arr => {
  const objectArray = arr.reduce(
    (a, v) => ({
      ...a,
      [camelize(v.termText.toLowerCase().replace(/[^a-zA-Z ]/g, ''))]:
        v.isChecked
    }),
    {}
  );

  return objectArray;
};

export const getJsonStringify = respon => {
  return JSON.stringify(respon);
};

export const getDeviceCust = () => {
  return navigator.userAgent;
};

export const getWidthDevice = () => {
  return window.innerWidth;
};

export const getHeightDevice = () => {
  return window.innerHeight;
};

export const getValidationMotherName = formData => {
  return regexMotherName.test(formData.mothersMaidenName.toLowerCase());
};

export const getSchemeCodeFromPartnerId = partnerId => {
  if (partnerId === PARTNER_ID_BPJS) {
    return SCHEME_CODE_PNNEW;
  } else if (
    partnerId === PARTNER_ID_BPJS_KASBON ||
    partnerId === PARTNER_ID_GENERAL_KASBON
  ) {
    return SCHEME_CODE_KASBON;
  } else {
    return SCHEME_CODE_PNANG;
  }
};

export const getTextType = partnerId => {
  const schemeCode = getSchemeCodeFromPartnerId(partnerId);
  if (isKasbon(schemeCode)) {
    return 'TNCK';
  } else {
    return 'DTRM';
  }
};

export const getSubCodeLoanScheme = (subCodeLoanScheme, subCode) => {
  if (
    (subCodeLoanScheme === '' && subCode === '') ||
    (subCodeLoanScheme.length === 0 && subCode.length === 0)
  )
    return '';
  else return subCodeLoanScheme.filter(e => e.subCode === subCode)[0].subCode;
};

export const getUserName = (name = '') => {
  if (name.length >= 30) return name.substring(0, 30) + '...';
  else return name;
};

export const isPartnerOpenSaving = (partnerId, subCode) => {
  const partnerSaving = [
    PARTNER_ID_WEBVIEW_APK,
    PARTNER_ID_GENERAL_KASBON,
    PARTNER_ID_BPJS
  ];
  const conditions = [
    partnerSaving.includes(partnerId) ||
      (partnerId === PARTNER_ID_GENERAL && subCode === 'RAYA')
  ];

  const isSatisfied = conditions.every(condition => condition);

  return isSatisfied;
};

export const isPartnerMigrasi = schemeCode => {
  const conditions = [
    schemeCode === SCHEME_CODE_PNNEW || schemeCode === SCHEME_CODE_PNANG
  ];

  const isSatisfied = conditions.every(condition => condition);

  return isSatisfied;
};

export const isKasbon = schemeCode => {
  if (schemeCode === SCHEME_CODE_KASBON) return true;
  else return false;
};

export const forceUpdateCompanyName = isCompanyUpdated => {
  if (isCompanyUpdated !== 'Y') return true;
  else return false;
};

export const checkMaintenanceBanner = (activatedOn, appStatus) => {
  if (
    activatedOn.find(
      item =>
        item.applicationStatus === appStatus ||
        item.applicationStatus === FIRST_APPLY_START
    )
  )
    return true;
  else return false;
};

export const addressWithoutNewLine = (address = '') => {
  return address.replace(/(\n)+/g, ' ');
};

export const getInterestKasbon = (
  interestRateKasbon,
  loanAmount = 0,
  tenure = 0
) => {
  let monthInDays = 30;
  let interestRate = interestRateKasbon / (monthInDays * 12);
  let interestBasedOnTenor = (interestRate / 100) * tenure;
  let interestLoan = Math.round(
    ((interestBasedOnTenor * Number(loanAmount) + Number.EPSILON) * 100) / 100
  );

  if (interestRate >= 0.15) {
    interestRate = interestRate.toFixed(2);
  } else {
    interestRate = interestRate.toFixed(1);
  }

  return {
    interestLoan: interestLoan,
    interestRate: interestRate
  };
};

export const capitalizedText = text => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
