import React, { useEffect, useState } from 'react';

const SafeAreaView = ({ children }) => {
  const [safeAreaInsets, setSafeAreaInsets] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  });

  useEffect(() => {
    const handleResize = () => {
      const safeAreaInsets = window.visualViewport || {
        safeAreaInsets: { top: 0, right: 0, bottom: 0, left: 0 }
      };
      setSafeAreaInsets(safeAreaInsets);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial setup

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        paddingTop: `${safeAreaInsets?.top}px`,
        paddingBottom: `${safeAreaInsets?.bottom}px`,
        paddingLeft: `${safeAreaInsets?.left}px`,
        paddingRight: `${safeAreaInsets?.right}px`
      }}
    >
      {children}
    </div>
  );
};

export default SafeAreaView;
