import * as actionTypes from './actionTypes';
import axiosGolang from 'lib/axios/axios-golang';
// import axiosOauth from '../../lib/axios/axios-oauth';
import axiosOauthGolang from '../../lib/axios/axios-oauth-golang';
// import axiosAuthenticate from '../../lib/axios/axios-authenticate';
import axiosAuthenticateGolang from '../../lib/axios/axios-authenticate-golang';
import { getDeviceDetails } from 'helpers/getDeviceDetails';
import {
  STANDARD_TEXT,
  CUSTOM_USERS,
  USERS,
  AUTHENTICATEUSER,
  OAUTH,
  SPLASH,
  DEVICE_REG,
  PROFILE,
  CHANGE_PHONE_NUMBER,
  CHANGE_PIN,
  AUTH_MODE,
  CUSTOM_RAYANET,
  VALIDATE_TOKEN
} from '../../common/config';
import { loading, setApplicationStatus } from './loanApply';
import {
  getChannelContextCode,
  getFirstAuthorizationMode,
  getJsonStringify,
  isObjectEmpty
} from '../../helpers/Utility';
import {
  sendEvent,
  sendException,
  sendEventRegist,
  sendEventLogin
} from 'helpers/analytics';

export const setError = message => {
  return {
    type: actionTypes.SET_ERROR,
    error: message
  };
};

export const setAppTnC = data => {
  return {
    type: actionTypes.SET_APP_TNC,
    appTnC: data
  };
};

export const setIsTncAgreement = value => {
  return {
    type: actionTypes.SET_IS_TNC_AGREEMENT,
    isTncAgreement: value
  };
};

export const setUpdateAgreement = value => {
  return {
    type: actionTypes.SET_UPDATE_TNC_AGREEMENT,
    updateTncAgreement: value
  };
};

export const setUserId = phoneNo => {
  localStorage.setItem('phonePrincipal', phoneNo);
  return {
    type: actionTypes.SET_USER_ID,
    phonePrincipal: phoneNo
  };
};

export const setPhoneNo = phoneNo => {
  localStorage.setItem('phoneNo', phoneNo);
  return {
    type: actionTypes.SET_PHONE_NUMBER,
    phoneNo: phoneNo
  };
};

export const setPhoneNoRaya = noHpRaya => {
  localStorage.setItem('noHpRaya', noHpRaya);
  return {
    type: actionTypes.SET_PHONE_NUMBER_RAYA,
    noHpRaya: noHpRaya
  };
};

export const setPhoneNumberFlexiNew = noHpFlexiNew => {
  localStorage.setItem('noHpFlexiNew', noHpFlexiNew);
  return {
    type: actionTypes.SET_PHONE_NUMBER_FLEXI_NEW,
    noHpFlexiNew: noHpFlexiNew
  };
};

export const setNewPhoneNo = phoneNo => {
  return {
    type: actionTypes.SET_NEW_PHONENO,
    phoneNo: phoneNo
  };
};

export const setRegisteredPin = pin => {
  return {
    type: actionTypes.SET_REG_PIN,
    pin: pin
  };
};

export const setOauthToken = (token, expiredTime) => {
  return {
    type: actionTypes.SET_OAUTH_TOKEN,
    token: token,
    expiredTime: expiredTime
  };
};

export const resetTypedPin = () => {
  return { type: actionTypes.RESET_TYPED_PIN };
};

export const inputPin = pin => {
  return {
    type: actionTypes.TYPING_PIN,
    typedPin: pin
  };
};

export const newPin = pin => {
  return {
    type: actionTypes.SET_NEW_PIN,
    newPin: pin
  };
};

export const delPin = () => {
  return {
    type: actionTypes.DEL_PIN
  };
};

export const setHomePath = path => {
  return {
    type: actionTypes.SET_HOME_PATH,
    path: path
  };
};

export const setIsLogin = status => {
  return {
    type: actionTypes.SET_IS_LOGIN,
    status: status
  };
};

export const setUserProfile = data => {
  return {
    type: actionTypes.SET_PROFILE_DATA,
    data: data
  };
};

export const setParameterAccess = params => {
  return {
    type: actionTypes.SET_PARAMETER_ACCESS,
    params: params
  };
};

export const setDeviceInformation = deviceInformation => {
  return {
    type: actionTypes.SET_DEVICE_INFORMATION,
    deviceInformation: deviceInformation
  };
};

export const resetLogout = () => {
  return {
    type: actionTypes.RESET_LOGOUT
  };
};

export const setDataRayaApps = data => {
  return {
    type: actionTypes.SET_DATA_RAYA_APPS,
    data: data
  };
};

export const setStatusAuthFlowRaya = params => {
  return {
    type: actionTypes.SET_STATUS_AUTH_FLOW_RAYA,
    statusAuthFlowRaya: params
  };
};

export const setCodeAuthFlowRaya = params => {
  return {
    type: actionTypes.SET_CODE_AUTH_FLOW_RAYA,
    statusCodeFlowRaya: params
  };
};

export const setIsRayaAgreement = params => {
  return {
    type: actionTypes.SET_IS_RAYA_AGREEMENT,
    isRayaAgreement: params
  };
};

export const setErrorRayaAgreement = params => {
  return {
    type: actionTypes.SET_ERROR_RAYA_AGREEMENT,
    errorRayaAgreement: params
  };
};

export const setSplashScreen = data => {
  const text = 'Kamu bisa dapetin pinjaman hingga 25jt dengan tenor 7 hari.';
  const text2 = 'Pakai pembayaran cicilan menggunakan autodebet tanpa ribet!';

  const splashData = data.filter(function (value, index) {
    return index !== 1;
  });
  const newState = splashData.map((obj, index) => {
    if (index === 0) {
      obj = { ...obj, description: text };
    } else if (index === 2) {
      obj = { ...obj, description: text2 };
    }
    return obj;
  });

  return {
    type: actionTypes.SET_SPLASH_SCREEN,
    data: newState
  };
};

export const splashScreen = action => {
  return (dispatch, getState) => {
    dispatch(loading(true));
    const header = {
      headers: {
        Authorization: getState().auth.VTtoken
      }
    };

    axiosGolang
      .get(SPLASH, header)
      .then(res => {
        dispatch(setSplashScreen(res.data.data));
        action(prev => !prev);
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const fetchAppTnC = (auth, param) => {
  return dispatch => {
    const config = { headers: { Authorization: auth.token } };
    !param?.notLoading && dispatch(loading(true));
    const linkSchemeCode =
      param.schemeCode !== 'KSBON' ? '&schemeCode=' + param.schemeCode : '';
    const linkSubCode = param.subCode ? '&subCode=' + param.subCode : '';
    axiosGolang
      .get(
        STANDARD_TEXT + param.textType + linkSchemeCode + linkSubCode,
        config
      )
      .then(res => {
        if (res.data.data) {
          param.push && param.push();
          dispatch(setAppTnC(res.data.data));
        } else {
          dispatch(setError('system'));
        }
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const phoneRegistration = (phoneNo, history, haveAccount, action) => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    const body = {
      userDetail: {
        userPhoneNumber: phoneNo
      }
    };

    const header = {
      headers: {
        Authorization: getState().auth.VTtoken
      }
    };

    axiosGolang
      .post(CUSTOM_USERS, body, header)
      .then(res => {
        return res;
      })
      .catch(err => {
        let responseStatus = err.response && err.response.status;
        //if responsestatus 400 : number already register, if 401 number not yet register
        if (responseStatus === 401) {
          if (getChannelContextCode(err, 'channel-context') === '120000') {
            dispatch(setError('system'));
            return;
          }

          if (haveAccount) {
            action && action();
            return;
          } else {
            dispatch(setPhoneNo(phoneNo));
            localStorage.removeItem('phoneNo', phoneNo);
            sendEventRegist({
              action: 'User Regist the Number',
              label: 'phone number'
            });
            dispatch(setApplicationStatus(''));
            history.push('/create-pin');
            return;
          }
        }

        if (err.code === 'ERR_NETWORK') {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        if (
          responseStatus === 400 &&
          getChannelContextCode(err, 'channel-context') === '107880'
        ) {
          if (haveAccount) {
            dispatch(setPhoneNo(phoneNo));
            //setappstatus
            dispatch(setApplicationStatus('FRESH_START'));
            history.push('/login');
            return;
          } else {
            action && action();
            return;
          }
        }

        if (
          responseStatus === 400 &&
          getChannelContextCode(err, 'channel-context') === '211096'
        ) {
          dispatch(setError('have-change-number'));
          return;
        }

        dispatch(setError('system'));
      })

      .finally(() => dispatch(loading(false)));
  };
};

//register
export const registerOTP = (token, phoneNumber, callback) => {
  return dispatch => {
    dispatch(loading(true));

    const diff = {
      deviceType: 'pg-wv-dc-typ-',
      deviceName: 'pg-wv-',
      deviceId: 'pg-wv-dc-id-'
    };

    const body = {
      deviceDetails: getDeviceDetails(phoneNumber, diff),
      isHPNumberChanged: 'N'
    };

    const header = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };

    const url = '/v1/banks/01/users' + DEVICE_REG;

    axiosGolang
      .put(url, body, header)
      .then(res => {
        return res;
      })
      .catch(err => {
        if (err.response) {
          if (
            getChannelContextCode(err, 'channel-context') === '108458' ||
            err.response.status === 401
          ) {
            callback && callback('success');
            // action && action.success && action.success()
            dispatch(loading(false));
            return;
          }
          if (getChannelContextCode(err, 'channel-context') === '211091') {
            callback && callback('failed');
            // dispatch(loading(false))
            return;
          }
        }
        if (!err.response) {
          dispatch(setError('connection'));
          dispatch(setOauthToken('', ''));
          dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          dispatch(setOauthToken('', ''));
          dispatch(loading(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '200604') {
          dispatch(setError('contact-cs'));
          sendEventRegist({
            action: 'Error while delivering the OTP',
            label: 'OTP'
          });
          dispatch(loading(false));
          dispatch(setOauthToken('', ''));
          dispatch(setIsLogin(false));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '100473') {
          return;
        }

        dispatch(setOauthToken('', ''));
        dispatch(setError('system'));
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const validateOTP = (phoneNumber, token, otp, action) => {
  return dispatch => {
    dispatch(loading(true));

    const diff = {
      deviceType: 'pg-wv-dc-typ-',
      deviceName: 'pg-wv',
      deviceId: 'pg-wv-dc-id-'
    };

    const body = {
      authorizationDetails: {
        oneTimePassword: otp
      },
      deviceDetails: getDeviceDetails(phoneNumber, diff),
      isHPNumberChanged: 'N'
    };

    const header = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    };

    const url = '/v1/banks/01/users' + DEVICE_REG;

    axiosGolang
      .put(url, body, header)
      .then(res => {
        if (res.status && res.status === 200) {
          dispatch(setApplicationStatus(''));
          action && action.success && action.success();
          sendEventLogin({
            action: 'User Successfully Input the OTP Login',
            label: 'OTP'
          });
          return;
        }
      })
      .catch(err => {
        if (err.response) {
          if (
            err.response.status === 400 &&
            getChannelContextCode(err, 'channel-context') === '100879'
          ) {
            sendEventLogin({
              action: 'User Input Wrong OTP Login',
              label: phoneNumber + ' - ' + getJsonStringify(err.response)
            });
            action && action.eventName && action.eventName(err.response);
            dispatch(setError('incorrect_otp'));
            action && action.failed && action.failed();
            dispatch(loading(false));
            return;
          }
        } else {
          if (err.toString().includes('Network Error')) {
            dispatch(setError('connection'));
          } else {
            dispatch(setError('system'));
          }
          dispatch(setIsLogin(false));
          dispatch(setOauthToken('', ''));
          dispatch(loading(false));
        }
      });
  };
};

export const createPin = (pin, phoneNumber, callback) => {
  return (dispatch, getState) => {
    const body = {
      passwordDetails: {
        reTypesignOnPassword: pin,
        signOnPassword: pin,
        signOnPasswordFlag: 'Y',
        transactionPasswordFlag: 'Y'
      },
      userDetail: {
        userPhoneNumber: phoneNumber
      }
    };

    const header = {
      headers: {
        Authorization: getState().auth.VTtoken,
        'Content-Type': 'application/json'
      }
    };
    dispatch(loading(true));
    axiosGolang
      .post(CUSTOM_USERS, body, header)
      .then(() => {
        dispatch(setRegisteredPin(pin));
        dispatch(setError(''));
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(setRegisteredPin(pin));
            callback && callback('success');
            return;
          }
          if (getChannelContextCode(err, 'channel-context') === '211091') {
            callback && callback('failed');
            dispatch(loading(false));
            return;
          }
        }

        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '200604') {
          sendEventRegist({
            action: 'Error while delivering the OTP',
            label: 'OTP'
          });
          dispatch(setError('contact-cs'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === '100473') {
          callback && callback('repeated');
          return;
        }

        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const createAccount = (auth, action) => {
  return (dispatch, getState) => {
    dispatch(loading(true));

    const diff = {
      deviceType: 'pg-wv-dc-typ-',
      deviceName: 'pg-wv',
      deviceId: 'pg-wv-dc-id-'
    };

    const body = {
      authenticationDetais: {
        oneTimePassword: auth.otp
      },
      deviceDetails: getDeviceDetails(auth.phoneNumber, diff),
      passwordDetails: {
        reTypesignOnPassword: auth.pin,
        signOnPassword: auth.pin,
        signOnPasswordFlag: 'Y',
        transactionPasswordFlag: 'Y'
      },
      userDetail: {
        userPhoneNumber: auth.phoneNumber
      },
      isTncAgreement: auth.isTncAgreement
    };

    const header = {
      headers: {
        Authorization: getState().auth.VTtoken,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .post(CUSTOM_USERS, body, header)
      .then(res => {
        action && action.success && action.success();
        if (res.status && (res.status === 201 || res.status === 200)) {
          localStorage.setItem('phoneNo', res.data.userDetail.loginUserId);
          dispatch(setUserId(res.data.userDetail.loginUserId));
          sendEventRegist({
            action: 'User Successfully Input the OTP Register',
            label: 'OTP'
          });
          dispatch(setError(''));
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 400) {
            if (getChannelContextCode(err, 'channel-context') === '105412') {
              dispatch(setError('otp-expired'));
            } else {
              dispatch(setError('incorrect_otp'));
            }
          }
          action && action.failed && action.failed();
          action && action.eventName && action.eventName(err.response);
          sendEventRegist({
            action: 'User Wrong Input The OTP Register',
            label: auth.phoneNumber + ' - ' + getJsonStringify(err.response)
          });
          sendException({
            desc: 'User Wrong Input The OTP',
            fatal: false
          });
        } else {
          if (err.toString().includes('Network Error')) {
            dispatch(setError('connection'));
          } else {
            dispatch(setError('system'));
          }
        }
      })
      .finally(() => dispatch(loading(false)));
  };
};

export const fetchOauthToken = (phoneNo, pin, history) => {
  return (dispatch, getState) => {
    const VTtoken = getState().auth.VTtoken;
    const clientId = getState().auth.clientId;
    const clientSecret = getState().auth.clientSec;

    const header = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: VTtoken
      }
    };

    const formBody = {
      bankId: '01',
      clientId: clientId,
      clientSecret: clientSecret,
      userId: phoneNo,
      userPin: pin
    };

    dispatch(loading(true));
    axiosOauthGolang
      .post(OAUTH, formBody, header)
      .then(res => {
        if (res.data.accessToken && res.data.expiresIn) {
          dispatch(setOauthToken(res.data.accessToken, res.data.expiresIn));
          dispatch(validateToken(res.data.accessToken, pin, history));
        }
      })
      .catch(err => {
        // 24032
        if (getChannelContextCode(err, 'channel-context') === '24032')
          dispatch(setError('incorrect_pin'));
        else if (
          getChannelContextCode(err, 'channel-context') === '24036' ||
          getChannelContextCode(err, 'channel-context') === '106803'
        )
          dispatch(setError('need_reset_pin'));
        else {
          localStorage.removeItem('phoneNo', '');
          localStorage.removeItem('phonePrincipal', '');
          dispatch(setError('system'));
        }

        dispatch(loading(false));
      });
  };
};

export const validateToken = (token, pin, history) => {
  return dispatch => {
    const header = {
      headers: {
        Authorization: token
      }
    };

    const body = {
      accessToken: token
    };

    axiosOauthGolang
      .post(VALIDATE_TOKEN, body, header)
      .then(data => {
        const userId = data.data?.ValidateTokenData?.userId.split('.')[0];
        dispatch(setUserId(userId));
        dispatch(setPhoneNo(data.data?.ValidateTokenData?.userPrincipal));
        history && history(token);
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          dispatch(setError('incorrect_pin'));
        } else if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
        dispatch(setOauthToken('', ''));
        dispatch(loading(false));
      });
  };
};

export const authenticateUser = (phoneNo, pin, history) => {
  return (dispatch, getState) => {
    const VTtoken = getState().auth.VTtoken;
    dispatch(loading(true));

    const url = AUTHENTICATEUSER;

    const body = {
      channelId: 'I',
      accessCode: pin,
      authenticationMode: 'SPWD',
      authenticationModeType: 'P',
      deviceId: '',
      tokenRequired: 'Y',
      isPrelogin: '',
      secret: '',
      rmPlusAuthentication: 'N',
      userId: localStorage.getItem('phonePrincipal') || phoneNo,
      bankId: '01'
    };

    const header = {
      headers: {
        Authorization: VTtoken
      }
    };

    axiosAuthenticateGolang
      .put(url, body, header)
      .then(res => {
        if (res.status === 200) {
          dispatch(setOauthToken(res.data?.AuthenticateData?.accessToken, ''));
          history && history(res.data?.AuthenticateData?.accessToken);
        }
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          dispatch(loading(false));
          dispatch(setError('incorrect_pin'));
        } else if (err.toString().includes('Network Error')) {
          dispatch(loading(false));
          dispatch(setError('connection'));
        } else if (
          getChannelContextCode(err, 'channel-context') === '106803' ||
          getChannelContextCode(err, 'channel-context') === '24036'
        ) {
          dispatch(loading(false));
          dispatch(setError('need_reset_pin'));
        } else {
          dispatch(loading(false));
          dispatch(setError('system'));
          sendEvent({
            category: 'Unexpected Error Login',
            action: getJsonStringify(err.response),
            label: phoneNo
          });
          sendException({
            desc: getJsonStringify(err.response),
            fatal: true
          });
        }
        dispatch(loading(false));
      });
    // .finally(() => dispatch(loading(false)))
  };
};

export const fetchUserProfile = auth => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + PROFILE;
    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .get(url, header)
      .then(res => {
        dispatch(setUserProfile(res.data));
      })
      .catch(err => {
        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const updatePin = (auth, param, action) => {
  return dispatch => {
    dispatch(loading(true));
    const url = USERS + CHANGE_PIN;

    const header = {
      headers: {
        Authorization: auth.oauthToken,
        'Content-Type': 'application/json'
      }
    };

    const body = {
      changePasswordOption: AUTH_MODE,
      signOnPasswordDetails: {
        oldPassword: param.old_pin,
        newPassword: param.new_pin,
        confirmPassword: param.new_confirm_pin
      }
    };

    axiosGolang
      .put(url, body, header)
      .then(() => {
        action && action();
        dispatch(loading(false));
      })
      .catch(err => {
        if (getChannelContextCode(err, 'channel-context') === '107302') {
          dispatch(setError('same_password'));
          return;
        }

        if (err.toString().includes('Network Error')) {
          dispatch(setError('connection'));
        } else {
          dispatch(setError('system'));
        }
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const updatePhoneNumber = (auth, param, action) => {
  return dispatch => {
    dispatch(loading(true));

    const url = USERS + DEVICE_REG + CHANGE_PHONE_NUMBER;

    const header = {
      headers: {
        Authorization:
          auth.subCode === 'RAYA' && !isObjectEmpty(auth.dataRayaAppsAuthFlow)
            ? auth.vtToken
            : auth.token,
        'Content-Type': 'application/json'
      }
    };

    const diff = {
      deviceType: 'wb-dt',
      deviceName: 'wb-dn',
      deviceId: 'wb-id'
    };

    let body = {};

    if (auth.subCode === 'RAYA' && !isObjectEmpty(auth.dataRayaAppsAuthFlow)) {
      if (param.isFirst) {
        body = {
          noHpFlexi: auth.phoneNo,
          userDetails: {
            userPhoneNumber: auth.phoneNoRaya
          }
        };
      } else {
        body = {
          noHpFlexi: auth.phoneNo,
          authorizationDetails: {
            oneTimePassword: auth.otp
          },
          userDetails: {
            userPhoneNumber: auth.phoneNoRaya
          },
          deviceDetails: getDeviceDetails(auth.phoneNoRaya, diff),
          isHPNumberChanged: 'Y'
        };
      }
    } else {
      if (param.isFirst) {
        body = {
          userDetails: {
            userPhoneNumber: auth.newPhoneNumber
          }
        };
      } else {
        body = {
          authorizationDetails: {
            oneTimePassword: auth.otp
          },
          userDetails: {
            userPhoneNumber: auth.newPhoneNumber
          },
          deviceDetails: getDeviceDetails(auth.newPhoneNumber, diff),
          isHPNumberChanged: 'Y'
        };
      }
    }

    axiosGolang
      .put(url, body, header)
      .then(res => {
        if (!param.isFirst) {
          dispatch(setPhoneNo(res.data.mobileNo));
          dispatch(setNewPhoneNo(''));
          dispatch(setOauthToken('', ''));
          if (!localStorage.getItem('phonePrincipal')) setUserId(auth.phoneNo);
        }
        param.modal && param.modal();
        dispatch(loading(false));
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        if (getChannelContextCode(err, 'channel-context') === null) {
          dispatch(setError('system'));
          return;
        }

        if (err.response && err.response.status === 401) {
          if (getFirstAuthorizationMode(err, 'channel-context')) {
            if (getFirstAuthorizationMode(err, 'channel-context') === 'OTP') {
              dispatch(
                setNewPhoneNo(
                  auth.subCode === 'RAYA' &&
                    !isObjectEmpty(auth.dataRayaAppsAuthFlow)
                    ? auth.phoneNoRaya
                    : auth.newPhoneNumber
                )
              );
              action && action.success && action.success();
              return;
            }

            // Gagal, Nomor sudah terpakai muncul dialog
            // 'Nomor sudah Teregistrasi'
          }
        } else if (err.response && err.response.status === 400) {
          // Gagal, Nomor sudah terpakai muncul dialog
          // 'Nomor sudah Teregistrasi'
          if (getChannelContextCode(err, 'channel-context') === '107880') {
            dispatch(setError('number-registered'));
            action && action.failed && action.failed(err.response);
            return;
          }
          if (getChannelContextCode(err, 'channel-context') === '211149') {
            dispatch(setError('number-not-exist'));
            return;
          }
          if (getChannelContextCode(err, 'channel-context') === '100879') {
            action && action.eventName && action.eventName(err.response);
            action && action.failed && action.failed();
            dispatch(setError('incorrect_otp'));
            return;
          }
        }
        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};

export const validateIsRayaAgreement = (auth, body, action) => {
  return dispatch => {
    dispatch(loading(true));

    const url = CUSTOM_RAYANET + '/agreement';

    const header = {
      headers: {
        Authorization: auth.token,
        'Content-Type': 'application/json'
      }
    };

    axiosGolang
      .post(url, body, header)
      .then(res => {
        if (res) {
          action && action.action();
          return res;
        }
      })
      .catch(err => {
        dispatch(setOauthToken('', ''));
        dispatch(setErrorRayaAgreement(true));

        if (!err.response) {
          dispatch(setError('connection'));
          return;
        }

        dispatch(setError('system'));
      })
      .finally(() => {
        dispatch(loading(false));
      });
  };
};
